@import '~sass/colors';
@import "~sass/variables";
@import "~sass/media";
@import "~sass/common/mixins/layout";
@import "~sass/placeholders";


.ot_mapToolbar {
  display: flex;
  overflow: visible;
  background: #fff;
  border-bottom: solid 1px #E9ECEF;
  align-items: center;
  z-index: 50;
  min-height: 52px;
  padding-left: 20px;
  // small devices
  @media (max-width: $tablet-max) {
    position: fixed;
    width: 100vw;
    flex-wrap: nowrap;
    overflow-x: visible;
  }
  // desktop
  @media (min-width: $desktop-min) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    flex-wrap: wrap !important;
    width: 100%;
    > button, .ot_mapToolFilter {
      width: 100px;
      // margin-right: 20px;
      // margin-bottom: 20px;
    }
  }
	.filterBar{		
		display: flex;
    overflow: visible;
    padding-right: 10px;
    .ot_ci_filterButton{
      @media (min-width: $desktop-min) {
        background: transparent;
      }
    }
	}
}

%toolbar {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: auto !important;
  height: 40px;
  padding: 0 10px;
  border-radius: 4px;
  background-color: $white;
  font-size: 12px;
}

.ot_map_back.btn.ot_primaryButton {
  // desktop
  padding: 0 20px;
  @media (min-width: $desktop-min) {
      margin: 0 !important;
      padding: 0 20px !important;
      box-sizing: content-box;
      flex: 0 0 100px;
      width: 100px;
      margin-right: 20px;
      border: none !important;
      background-color: transparent !important;
      span{
        color: #343A40 !important;
      }
      svg .ot_svg_fill{
        fill: #343A40 !important;
      }
  }
}

.ot_mapToolFilter.ot_ci_dropContainer {
  @extend %toolbar;
  padding: 0;
  height: auto !important;
  .drop_placeholder .drop_menu {
    width: auto !important;
  }

  .ot_ci_filterButton {
    color: #343A40 !important;
    width: 100%;
    height: 32px !important;
    border-radius: 20px !important;
    border-color: #CED4DA !important;
    padding: 0 12px !important;
    // @extend %ot_box_shadow_desktop;
    &:hover, &:focus &:active {
      background-color: transparent !important;
    }
    &.active{
      border-color: #343A40 !important;
    }
  }
  
.ot_ci_filterButton_border{
  	border-color: $black !important;
}
  // small devices
  @media (max-width: $tablet-max) {
    min-height: 40px !important;
    .ot_ci_filterButton {
      box-sizing: content-box;
      padding: 0 10px;
    }
    .drop_placeholder {
      position: fixed;
      top: 100px !important;
      left: 0px;
      // left: -30vw !important;
      // right: auto !important;
      width: 100vw !important;
    }
    .drop_menuContainer {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

.ot_filter_wheel_chair {
    padding-right : 6px;
  }
}

.ot_mapFilterMenu.ot_callout {
  padding: 20px;
  box-shadow: 0px 1px 4px rgba(33, 37, 41, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.08);
  background-color: $white;
 	// small devices
   @media (max-width: $tablet-max) {
    width: 100vw;
    margin-top: 16px;
    border-radius: 0;
  	.rangeLabel{
 		font-size: 14px;
 		width: 42vw;
  	}
  	.rangeInput input {
  		overflow : visible;
   		width: 42vw;
   		height: 40px;
  	}
  	 .inputBoxHyphen{
  		float: left;
  		font-size: 14px;
  		padding-bottom: 22px;
  		margin-left: 2vw;
  		margin-right: 2vw;
    }
   }
   @media (max-width: $screen-xs-max) {
      margin-top: 2px;
    }
   // desktop
   @media (min-width: $desktop-min) {
     width: 260px;
     margin-top: 14px;
     margin-left: 20px;
   .rangeLabel{
  		overflow : visible;
 		font-size: 14px;
 		width: 86px;
  	}
   .rangeInput input {
   		width: 86px;
   		height: 40px;
  	}
  	.inputBoxHyphen{
  		float: left;
  		font-size: 14px;
  		padding-bottom: 22px;
  		margin-left: 1px;
  		margin-right: 0px;
    }
    
  }
  .rangeInputs{
    display: flex;
    justify-content: space-between;

    .rangeMin, .rangeMax{
      flex-grow: 1;
      color: #868E96;
      input{
        width: 100%;
        color: #343A40;
      }
    }

    .inputHyphen{
      align-self: center;
      color: #868E96;
      padding: 0 8px;
    }
  }

  .rangeSlider{
    margin-left: -10px;
    margin-bottom: 15px;
  }

  .buttonRow{
    display: flex;
    justify-content: flex-end;

    button{
      width: auto;
      padding: 0 10px;
    }
  }

  h5, .filters_container > li {
    margin-bottom: 15px;
  }
  
  .resetButton{
  	// width: 70px;
    background: #F1F3F5;
    border-radius: 3px;
    font-size: 16px;
    &:hover{
      background: darken(#F1F3F5, 10%);
    }
  }
  
  .applyButton{
  	// width: 70px;
  	font-size: 16px;
  	margin-left:8px;
  }

}

.ot_accessibilityFilterMenu.ot_callout {
  padding: 20px;
  box-shadow: 0px 1px 4px rgba(33, 37, 41, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.08);
 	// small devices
   @media (max-width: $tablet-max) {
  	width: 100vw;
  	.rangeLabel{
 		font-size: 14px;
 		width: 42vw;
  	}
  	.rangeInput input {
  		overflow : visible;
   		width: 42vw;
   		height: 40px;
  	}
  	 .inputBoxHyphen{
  		float: left;
  		font-size: 14px;
  		padding-bottom: 22px;
  		margin-left: 2vw;
  		margin-right: 2vw;
    }
   }
   // desktop
   @media (min-width: $desktop-min) {
     width: 320px;
     left : 20px;
     top : 14px;
   .rangeLabel{
  		overflow : visible;
 		font-size: 14px;
 		width: 86px;
  	}
   .rangeInput input {
   		width: 86px;
   		height: 40px;
  	}
  	.inputBoxHyphen{
  		float: left;
  		font-size: 14px;
  		padding-bottom: 22px;
  		margin-left: 1px;
  		margin-right: 0px;
    }
   }
  background-color: $white;
  h5, .filters_container > li {
    margin-bottom: 15px;
  }

  .resetButton{
  	width: 70px;
  	font-size: 16px;  	
  }
  
  .applyButton{
  	width: 70px;
  	font-size: 16px;
  	margin-left:20px;
  }

}

.ot_map_title {
  flex: 1 0 auto;
  margin: 0 auto;
  order: 3;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.legendWrap{
  display: block;
  position: absolute;
  top: 72px;
  margin-left: 20px;
  // < 1199px
  @media (max-width: $tablet-max) {
    margin-top: 17px;
  }

  &.isFixedPlus{
    top: 0;
  }
}

.ot_mapLegend {
  border: 1px solid $gray-light;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
  background-color: $white;
  box-shadow: 0px 1px 4px rgba(33, 37, 41, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  > .legend {
    align-items: center;
  }
  .legend {
    margin: 0 !important;
    color: $gray-light;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-shrink: 0;
    &.showcased {
      padding-right: 15px !important;
      opacity: 1;
      &:before {
        content: '';
        display: block;
        margin-right: 8px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: currentColor;
        flex-shrink: 0;
      }
    }
    &.hidden  {
      padding-right: 0 !important;
      border: none !important;
      opacity: 0 !important;
      max-width: 0 !important;
      margin-right: 0 !important;
    }

    &.companion {
      position: relative;
      min-width: 120px;
      &:after {
        content: 'C';
        position: absolute;
        top: 10px;
        left: 5px;
        z-index: 10;
        display: block;
        width: 20px;
        height: 20px;
        color: #fff;
        font-weight: 500;
      }
    }
    span{
      color: #343A40;
    }
    &.sectionView{
      padding-top: 12px;
      padding-bottom: 12px;
      align-items: center;
      &:first-child{
        padding-left: 16px;
      }
      &:last-child{
        padding-right: 16px;
      }
    }
  }
  .legendDropdown{
    width: 100%;
    button{
      padding: 12px 16px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.open{
        svg{
          transition: all 0.3s;
          transform: rotate(-180deg);
        }
      }
      span{
        margin: 0;
        font-size: 14px;
      }
      svg{
        fill: #343A40 !important;
        margin-left: 10px;
        transition: all 0.3s;
      }
    }
    .legendDropdown_content{
      border-top: solid 1px #CED4DA;
      padding-top: 16px;
      height: 100%;
      overflow: auto;
      max-height: calc(100vh - 200px); // 200 is a combination of heights of the Header, the white bar and their paddings
      @media (max-width: $tablet-max) {
        max-height: calc(100vh - 450px); // 450 is a combination of heights of the Header, white bar, footer(expanded fully) + nav bar height and their paddings and the selected seats widget at the bottom of the page
      }
      li{
        padding: 0 16px 16px;
        .ddSeatDetails{
          flex-grow: 1;
          span{
            display: block;
            color: #343A40;
            flex-grow: 1;
          }
          span:not(.seatName){
            
          }
          svg{
            fill: #495057;
            margin-right: 10px;
          }
          &.altSeats{
            display: flex;
            align-items: center;
          }
        }
        .seatName{
          font-size: 14px;
          font-weight: 600;
        }
      }
      .divider{
        height: 1px;
        background: #E9ECEF;
        margin-bottom: 16px;
      }
    }
  }
}