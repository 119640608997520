@import "~sass/media";

.accessibilityFilterMenu_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .accessibilityFilter_label{
        height: 24px;
        left: 20px;
        top: 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #343A40;
    }

   
}

.accessibilityFilter_description{
    padding-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #796D85;
}
.accessibilityFilter_footer{
    padding-top: 34px;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}


.accessibilityFilter_checkBox {
    .switch {
        position: relative;
        display: inline-block;
        width: 34px;
        height: 20px;
        cursor: not-allowed;
      }
      
      .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
        cursor: not-allowed;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        cursor: not-allowed;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(14px);
        -ms-transform: translateX(14px);
        transform: translateX(14px);
      }
      
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
}