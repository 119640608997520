@import "~sass/media";
@import "~sass/common/styles/animations";

.ot_ci_dropContainer {
  position: relative;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &.active {
    z-index: 1100;
  }
  .drop_menuContainer {
    position: relative;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-right: 1vw;
    }
  }
}

.loginMargin {
  margin-right: 40px;
}

.ot_ci_dropContainer.navbarDropMenu {
  .dropMenu{
    width: unset !important;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: auto !important;
    }
  }
}

.drop_placeholder.dropMenu  {
  position: absolute;
  width: 100vw;
  overflow-y: hidden;
  &.up, &.top {
  top: 0px;
  right: 0px;
  }
  &.right {
    top: 0px;
    right: 0px;
  }
  &.down,
  &.bottom {
    top: 100%;
    right: 0px;
    @media (min-width: $desktop-min) {
      left: -20px !important;
    }
  }
  &.right {
    top: 0px;
    left: 0px;
  }
}

.drop_placeholder.slideMenu {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  // mobile
  @media (max-width: $screen-sm-max) {
    &.active {
      width: 100vw;
    }
    &.active:before {
      content: '';
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 1200;
      width: 100vw;
      height: 100vh;
      background-color: rgba( 0, 0, 0, 0.3 );
      @include fadeIn();
    }
  }
  .drop_menuContainer {
    width: 60vw;
    height: 100vh;
    z-index: 1300;
    > * {
      width: 60vw;
      height: 100vh;
    }
    @media (min-width: $screen-md-min) {
      background-color: #ffffff !important;
      box-shadow: -1px 0px 5px #888888;
    }

  }

  .drop_menuContainer_cart {
    width: 30vw !important;
  }

}

.drop_menuContainer .ot_ci_menuItem {
  @media (max-width: $screen-sm-max) {
    overflow: hidden;
  }
}
